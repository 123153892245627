@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /* We set primary color as variable so it can be overriden per customer */
    /* HEX #330072 */
    --color-primary-800: 51 0 114;

    /* HEX #240555 */
    --color-primary-900: 36 5 85;
  }

  a {
    @apply text-info-600 hover:text-info-700;
  }

  h1 {
    @apply text-2xl;
  }

  h2 {
    @apply text-xl;
  }

  h3 {
    @apply text-lg;
  }
}

/*
 * Mapbox style overwrites
/*

/* Make took buttons look similar to Revere buttons */
.has-filters .mapboxgl-ctrl-group.mapboxgl-ctrl {
  @apply absolute z-30 mr-0 mt-0 flex items-center gap-2 border-none p-2 shadow-lg md:flex;
}

/* Polygon controls */
.mapboxgl-ctrl-top-right .mapboxgl-ctrl-group.mapboxgl-ctrl {
  @apply right-4 top-20 flex-col rounded transition-transform max-md:-translate-x-[100dvw] md:right-[535px] md:top-10;
}

/* Polygon controls hidden on mobile */
.sidebar-hidden .mapboxgl-ctrl-top-right .mapboxgl-ctrl-group.mapboxgl-ctrl {
  @apply max-md:translate-x-0;
}

.mapboxgl-ctrl-bottom-left .mapboxgl-ctrl-group {
  @apply bottom-[9.25rem] left-2 flex flex-row-reverse rounded-full md:bottom-8 md:left-0;
}

.has-filters .mapboxgl-ctrl-bottom-left .mapboxgl-ctrl-group.mapboxgl-ctrl {
  @apply transition max-md:-translate-x-[120%];
}
.sidebar-hidden .has-filters .mapboxgl-ctrl-bottom-left .mapboxgl-ctrl-group.mapboxgl-ctrl {
  @apply max-md:translate-x-0;
}

.mapboxgl-ctrl-bottom-left .mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  @apply mt-[2px] bg-transparent;
}

.mapboxgl-map .mapboxgl-control-container .mapboxgl-ctrl button {
  @apply rounded;
}

.mapboxgl-map .mapboxgl-control-container .mapboxgl-ctrl button:not(:disabled) {
  @apply hover:bg-primary-50;
}
.mapboxgl-map .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out,
.mapboxgl-map .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in {
  @apply rounded-full;
}

.mapboxgl-map .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon[title='Zoom out'] {
  background-image: url('/images/zoom-out-icon.svg');
}

.mapboxgl-map .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon[title='Zoom in'] {
  background-image: url('/images/zoom-in-icon.svg');
}

.mapboxgl-ctrl-group button:last-child {
  border-top: none;
}

.mapboxgl-ctrl-group .mapbox-gl-draw_ctrl-draw-btn {
  @apply size-7;
}

/* Update tool icons */
.mapboxgl-ctrl-group .mapbox-gl-draw_polygon {
  background-image: url('/images/polygon-tool.svg');
}

.mapboxgl-ctrl-group .mapbox-gl-draw_trash {
  background-image: url('/images/delete-polygon-tool.svg');
}

/* Display copyright notice below mapbox log */
.mapboxgl-ctrl-bottom-left {
  @apply flex flex-row-reverse;
}

/*
*
* JSON Syntax Highlighting 
*
* */
.__json-pretty__ {
  @apply bg-gray-800 text-sm leading-5 text-gray-400;
}

.__json-pretty__ .__json-key__ {
  @apply text-primary-100;
}

.__json-pretty__ .__json-value__ {
  @apply text-warning-200;
}

.__json-pretty__ .__json-string__ {
  @apply text-info-300;
}

.__json-pretty__ .__json-boolean__ {
  @apply text-warning-300;
}

.__json-pretty-error__ {
  @apply text-warning-500;
}

input[type='checkbox'] {
  @apply rounded-[3px] text-primary;
}

input[type='datetime-local']::-webkit-calendar-picker-indicator,
input[type='date']::-webkit-calendar-picker-indicator {
  background-image: url(/images/datepicker-icon.svg);
}

.hide-scrollbar {
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.rich-text {
  @apply text-base font-normal text-black sm:text-sm;
}

.rich-text :is(ul, ol) {
  @apply list-outside;
  @apply ml-4;
}

.rich-text ul {
  @apply list-disc;
}

.rich-text ol {
  @apply list-decimal;
}

.rich-text p {
  @apply my-4;
}

.rich-text img[width][height] {
  @apply !h-auto !max-w-full;
}

.rich-text .mention {
  @apply rounded bg-info-50 font-medium text-info-700 outline outline-2 outline-info-50;
}

div[data-radix-popper-content-wrapper] {
  z-index: 100 !important;
}

.combobox-options {
  transform-origin: top left;
  animation: scaleIn 0.2s ease-in-out;
  width: var(--radix-popover-trigger-width);
}
.combobox-options[data-side='top'] {
  transform-origin: bottom left;
}
.combobox-options[data-side='bottom'] {
  transform-origin: top left;
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.skeleton {
  overflow: hidden;
  position: relative;
}

.skeleton::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(to right, rgb(243, 242, 241) 0%, rgb(237, 235, 233) 50%, rgb(243, 242, 241) 100%) 0px 0px / 90% 100% no-repeat
    rgb(243, 242, 241);
  transform: translateX(-100%);

  animation-name: skeleton-animation;
  animation-duration: var(--skeleton-animation-duration, 4s);
  animation-timing-function: ease-in-out;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-delay: var(--skeleton-animation-duration, 0s);
}

@keyframes skeleton-animation {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
